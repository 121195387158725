import classNames from 'classnames';
import React from 'react';
import Livequiz from '../../../common/Livequiz/Livequiz';
import Logo from '../../../common/logo/Logo';
import styles from './Welcome.module.css';

export default function Welcome(props) {
    if (props.gameData.teamMode) {
        return (
            <div>
                <Logo/>
                <Livequiz />
                <div className={styles.team}><span>{props.name}</span></div>
                <div>Cherche ton nom à l&apos;écran</div>
                <div>
                    <ul className={styles.ul}>
                        {props.team.players.map((player, i) => (
                            <li key={i} className={classNames(styles.li, 'bango')}>{player.name}</li>
                        ))}
                    </ul>
                    {props.gameData.started
                        && (
                            <div className={styles.topMargin}>
                                <div>Préparez-vous !</div>
                                <div>L&apos;équipe pourra bientôt rejoindre la partie</div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
    return (
        <>
            <Logo/>
            <Livequiz />
            <div>Bienvenue <span className={classNames(styles.name, 'bango')}>{props.name}</span> {props.gameData.started
                && (
                    <div className={styles.topMargin}>
                        <div>Prépare-toi !</div>
                        <div>Tu pourras bientôt rejoindre la partie</div>
                    </div>
                )
            }</div>
        </>
    );
}
