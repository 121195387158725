import { toast } from 'react-toastify';
import Constants from './constants';

/**
 * @description Auto configure the toast container
 */
toast.configure();

/**
 * @class Toaster
 * @description A simple helper class to centralize toasts
 * @export
 * @class Toaster
 */
export default class Toaster {
    static get toast() {
        return toast;
    }

    static pinNotFound() {
        toast.error('Le code PIN n\'a pas été reconnu', {
            toastId: 'pin-error',
        });
    }

    static pseudoConstraints() {
        toast.warn(`Le pseudo doit contenir entre ${Constants.CONSTRAINTS.name.min} et ${Constants.CONSTRAINTS.name.max} caractères`, {
            toastId: 'pseudo-constraints-error',
        });
    }

    static pseudoAlreadyUsed() {
        toast.error('Ce pseudo est déjà utilisé', {
            toastId: 'pseudo-exists-error',
        });
    }

    static nameConstraints() {
        toast.warn(`Le nom de l'équipe doit contenir entre ${Constants.CONSTRAINTS.name.min} et ${Constants.CONSTRAINTS.name.max} caractères`, {
            toastId: 'team-constraints-error',
        });
    }

    static nameAlreadyUsed() {
        toast.error('Ce nom est déjà utilisé', {
            toastId: 'team-exists-error',
        });
    }

    static gameLocked() {
        toast.warn('Ce jeu a été verrouillé, demande au présentateur de te laisser le rejoindre !', {
            toastId: 'locked-error',
        });
    }

    static gameUnavailable() {
        toast.warn('Ce jeu n\'est pas ou plus disponible.', {
            toastId: 'game-unavailable-error',
        });
    }

    static presenterDisconnected() {
        toast.warn('Le présentateur est déconnecté', {
            toastId: 'presenter-disconnect-error',
        });
    }

    static banned() {
        toast.warn('Oh non ! Tu as été expulsé du jeu.', {
            toastId: 'banned-error',
        });
    }

    static dos() {
        toast.error('Trop de tentatives, attendez un peu et réessayez', {
            toastId: 'dos-error',
        });
    }
}
