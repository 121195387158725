import classNames from 'classnames';
import React from 'react';
import Constants from '../../common/js/constants';
import Toaster from '../../common/js/toaster';
import { nameInputFormatter } from '../../common/js/utils';
import { nameExists } from '../../common/js/web-service';
import Button from '../common/button/Button';
import Input from '../common/input/Input';
import Livequiz from '../common/Livequiz/Livequiz';
import Logo from '../common/logo/Logo';
import styles from './TeamNameForm.module.css';

export default class TeamNameForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            status: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({
            value: nameInputFormatter(e.target.value),
            status: '',
        });
    }

    async handleSubmit(e) {
        e.preventDefault();
        if (this.state.value.length >= Constants.CONSTRAINTS.name.min) {
            const { error, result } = await nameExists(this.props.pin, window.encodeURIComponent(this.state.value));
            if (error) {
                this.setState({
                    status: styles.invalid,
                });
                if (error === 'game not found') {
                    Toaster.pinNotFound();
                } else if (error === 'DOS') {
                    Toaster.dos();
                } else {
                    Toaster.toast.error(error);
                }
            } else if (result) {
                Toaster.nameAlreadyUsed();
            } else {
                this.props.setTeamName(this.state.value);
            }
        } else {
            Toaster.nameConstraints();
        }
    }

    render() {
        const { value } = this.state;
        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <div>
                        Entrez le <span className="yellow"><strong>Nom</strong></span> de l'équipe
                    </div>
                    <div className={styles.nameContainer}>
                        <Input
                            className={classNames(styles.input, this.state.status)}
                            value={value}
                            onChange={this.handleChange}
                            size={Constants.CONSTRAINTS.name.max}
                            placeholder="Nom de l'équipe"
                        />
                    </div>
                    <div>
                        <Button>Valider</Button>
                    </div>
                </form>
            </>
        );
    }
}
