import classNames from 'classnames';
import React from 'react';
import { ReactComponent as Correct } from '../../../../assets/img/correct.svg';
import { ReactComponent as Incorrect } from '../../../../assets/img/incorrect.svg';
import { BackgroundContext } from '../../../common/background-context/BackgroundContext';
import styles from '../../Game.module.css';

export default function Feedback(props) {
    const { changeBackground } = React.useContext(BackgroundContext);
    React.useEffect(() => {
        changeBackground('intro');
    }, []);

    let result = 'Temps écoulé';
    let chain = '';
    if (props.responded) {
        result = props.isCorrect ? 'Bonne réponse' : 'Mauvaise réponse';

        if (props.oldCorrectResponsesChain > 0
            || props.correctResponsesChain > 0) {
            const str = 'Série de bonnes réponses';
            chain = props.correctResponsesChain > 0
                ? (
                    <div className={styles.background}>{str} : <span className={classNames(styles.responsesChain, 'yellow')}>{props.correctResponsesChain}</span></div>
                )
                : (
                    <div className={styles.background}>{str} interrompue</div>
                )
        }
    }
    return (
        <div>
            <div className={classNames(styles.margin)}>{props.isCorrect
                ? <Correct className={styles.svg}/>
                : <Incorrect className={styles.svg}/>}
            </div>
            <div className={classNames(styles.result, 'bango')}>{result} !</div>
            <div>{chain}</div>
            <div className={classNames(styles.margin, styles.points, 'bango')}>
                {props.points > 0 
                    ? (
                        <>
                            <div>+{props.points}</div>
                            <div className={styles.pointsText}>points</div>
                        </>
                    )
                    : (
                        <div className={styles.min}>Ce n'est pas encore fini !</div>
                    )
                }
            </div>
            <div className={styles.podiumText}>{typeof props.rank !== 'undefined' && (props.rank <= 3 ? 'Tu es sur le podium !' : `Tu es à la ${props.rank}e place`)}</div>
        </div>
    );
}
