import classNames from 'classnames';
import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import Constants from '../../../../common/js/constants';
import Button from '../../../common/button/Button';
import styles from './Order.module.css';

export default class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            list: props.data.renderData.proposals.map((p, i) => Object.assign(p, { originalIndex: i })),
        };
        this.handleSortableUpdate = this.handleSortableUpdate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.ref = React.createRef();
    }

    handleSortableUpdate() {
        getDomProposals(this.ref.current).forEach((domProposal, index) => {
            const dp = domProposal;
            dp.dataset.index = index;
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const responses = getDomProposals(this.ref.current).map(({ dataset }) => (
            { id: dataset.id, index: dataset.index }
        ));
        this.props.onResponse(this.props.data.id, responses);
    }

    render() {
        const { list } = this.state;
        const proposals = list.map(({ id, originalIndex }) => (
            <div key={id} data-id={id} className={classNames(styles.sortable_item, getClassName(originalIndex))}>
                <div className={styles.orderIcon}>
                    <i className={classNames('icon-circle-chevrons', styles.fontIcon)}></i>
                </div>
            </div>
        ));

        return (
            <form className={styles.wrapper} onSubmit={this.handleSubmit}>
                <div ref={this.ref} className={styles.sortable_container}>
                    <ReactSortable
                        list={list}
                        setList={(newState) => this.setState({ ...this.state, list: newState })}
                        onUpdate={this.handleSortableUpdate}
                        animation={150}
                        forceFallback={true}
                        fallbackOnBody={true}
                    >
                        {proposals}
                    </ReactSortable>
                </div>
                <div className={styles.validation}>
                    <Button>Valider</Button>
                </div>
            </form>
        );
    }
}

function getClassName(index) {
    return `${Constants.COLORS[index]}`;
}

function getDomProposals(currentRef) {
    return [...currentRef.querySelectorAll(`.${styles.sortable_item}`)];
}
