import classNames from "classnames";
import React from "react";
import styles from "./Img.module.css";

export default function Img(props) {
    const {
        className = '',
        alt = '',
        ...domNativeAttributes
    } = props;
    return (
        <img
            className={classNames(styles.img, className)}
            alt={alt}
            src={props.src}
            { ...domNativeAttributes }
        />
    );
}
