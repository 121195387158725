import classNames from 'classnames';
import React from 'react';
import Loader from 'react-loader-spinner';
import styles from './CountDown.module.css'

export default function CountDown(props) {
    const [counter, setCounter] = React.useState(props.duration);

    let timeout = -1;
    React.useEffect(() => {
        if (counter > 0) {
            timeout = setTimeout(() => setCounter(counter - 1), 1000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [counter]);

    return (
        <div>
            <div className={styles.content}>
                <Loader
                    type="Oval"
                    color="#fff"
                    height={'30vmin'}
                    width={'30vmin'}
                />
                <div className={classNames(styles.count, 'bango', 'bounce-in')}>{counter}</div>
            </div>
            <div>{props.team ? "Discussion d'équipe" : 'Prêt ?'}</div>
        </div>
    );
}
