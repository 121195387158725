import { fetch as fetchPolyfill } from 'whatwg-fetch';
import Constants from './constants';

export function isIEorEdge() {
    return /msie\s|trident\/|edge\//i.test(navigator.userAgent)
           && !!(document.uniqueID || window.MSInputMethodContext);
}

export const fetchMethod = isIEorEdge() ? fetchPolyfill : fetch;


/**
 * @constant CHARACTERS_MAP
 * @description A map of accented/non accented character key/value pairs
 */
 const CHARACTERS_MAP = {
    À: 'A',
    Á: 'A',
    Â: 'A',
    Ã: 'A',
    Ä: 'A',
    Å: 'A',
    Ấ: 'A',
    Ắ: 'A',
    Ẳ: 'A',
    Ẵ: 'A',
    Ặ: 'A',
    Æ: 'AE',
    Ầ: 'A',
    Ằ: 'A',
    Ȃ: 'A',
    Ç: 'C',
    Ḉ: 'C',
    È: 'E',
    É: 'E',
    Ê: 'E',
    Ë: 'E',
    Ế: 'E',
    Ḗ: 'E',
    Ề: 'E',
    Ḕ: 'E',
    Ḝ: 'E',
    Ȇ: 'E',
    Ì: 'I',
    Í: 'I',
    Î: 'I',
    Ï: 'I',
    Ḯ: 'I',
    Ȋ: 'I',
    Ð: 'D',
    Ñ: 'N',
    Ò: 'O',
    Ó: 'O',
    Ô: 'O',
    Õ: 'O',
    Ö: 'O',
    Ø: 'O',
    Ố: 'O',
    Ṍ: 'O',
    Ṓ: 'O',
    Ȏ: 'O',
    Ù: 'U',
    Ú: 'U',
    Û: 'U',
    Ü: 'U',
    Ý: 'Y',
    à: 'a',
    á: 'a',
    â: 'a',
    ã: 'a',
    ä: 'a',
    å: 'a',
    ấ: 'a',
    ắ: 'a',
    ẳ: 'a',
    ẵ: 'a',
    ặ: 'a',
    æ: 'ae',
    ầ: 'a',
    ằ: 'a',
    ȃ: 'a',
    ç: 'c',
    ḉ: 'c',
    è: 'e',
    é: 'e',
    ê: 'e',
    ë: 'e',
    ế: 'e',
    ḗ: 'e',
    ề: 'e',
    ḕ: 'e',
    ḝ: 'e',
    ȇ: 'e',
    ì: 'i',
    í: 'i',
    î: 'i',
    ï: 'i',
    ḯ: 'i',
    ȋ: 'i',
    ð: 'd',
    ñ: 'n',
    ò: 'o',
    ó: 'o',
    ô: 'o',
    õ: 'o',
    ö: 'o',
    ø: 'o',
    ố: 'o',
    ṍ: 'o',
    ṓ: 'o',
    ȏ: 'o',
    ù: 'u',
    ú: 'u',
    û: 'u',
    ü: 'u',
    ý: 'y',
    ÿ: 'y',
    Ā: 'A',
    ā: 'a',
    Ă: 'A',
    ă: 'a',
    Ą: 'A',
    ą: 'a',
    Ć: 'C',
    ć: 'c',
    Ĉ: 'C',
    ĉ: 'c',
    Ċ: 'C',
    ċ: 'c',
    Č: 'C',
    č: 'c',
    C̆: 'C',
    c̆: 'c',
    Ď: 'D',
    ď: 'd',
    Đ: 'D',
    đ: 'd',
    Ē: 'E',
    ē: 'e',
    Ĕ: 'E',
    ĕ: 'e',
    Ė: 'E',
    ė: 'e',
    Ę: 'E',
    ę: 'e',
    Ě: 'E',
    ě: 'e',
    Ĝ: 'G',
    Ǵ: 'G',
    ĝ: 'g',
    ǵ: 'g',
    Ğ: 'G',
    ğ: 'g',
    Ġ: 'G',
    ġ: 'g',
    Ģ: 'G',
    ģ: 'g',
    Ĥ: 'H',
    ĥ: 'h',
    Ħ: 'H',
    ħ: 'h',
    Ḫ: 'H',
    ḫ: 'h',
    Ĩ: 'I',
    ĩ: 'i',
    Ī: 'I',
    ī: 'i',
    Ĭ: 'I',
    ĭ: 'i',
    Į: 'I',
    į: 'i',
    İ: 'I',
    ı: 'i',
    Ĳ: 'IJ',
    ĳ: 'ij',
    Ĵ: 'J',
    ĵ: 'j',
    Ķ: 'K',
    ķ: 'k',
    Ḱ: 'K',
    ḱ: 'k',
    K̆: 'K',
    k̆: 'k',
    Ĺ: 'L',
    ĺ: 'l',
    Ļ: 'L',
    ļ: 'l',
    Ľ: 'L',
    ľ: 'l',
    Ŀ: 'L',
    ŀ: 'l',
    Ł: 'l',
    ł: 'l',
    Ḿ: 'M',
    ḿ: 'm',
    M̆: 'M',
    m̆: 'm',
    Ń: 'N',
    ń: 'n',
    Ņ: 'N',
    ņ: 'n',
    Ň: 'N',
    ň: 'n',
    ŉ: 'n',
    N̆: 'N',
    n̆: 'n',
    Ō: 'O',
    ō: 'o',
    Ŏ: 'O',
    ŏ: 'o',
    Ő: 'O',
    ő: 'o',
    Œ: 'OE',
    œ: 'oe',
    P̆: 'P',
    p̆: 'p',
    Ŕ: 'R',
    ŕ: 'r',
    Ŗ: 'R',
    ŗ: 'r',
    Ř: 'R',
    ř: 'r',
    R̆: 'R',
    r̆: 'r',
    Ȓ: 'R',
    ȓ: 'r',
    Ś: 'S',
    ś: 's',
    Ŝ: 'S',
    ŝ: 's',
    Ş: 'S',
    Ș: 'S',
    ș: 's',
    ş: 's',
    Š: 'S',
    š: 's',
    Ţ: 'T',
    ţ: 't',
    ț: 't',
    Ț: 'T',
    Ť: 'T',
    ť: 't',
    Ŧ: 'T',
    ŧ: 't',
    T̆: 'T',
    t̆: 't',
    Ũ: 'U',
    ũ: 'u',
    Ū: 'U',
    ū: 'u',
    Ŭ: 'U',
    ŭ: 'u',
    Ů: 'U',
    ů: 'u',
    Ű: 'U',
    ű: 'u',
    Ų: 'U',
    ų: 'u',
    Ȗ: 'U',
    ȗ: 'u',
    V̆: 'V',
    v̆: 'v',
    Ŵ: 'W',
    ŵ: 'w',
    Ẃ: 'W',
    ẃ: 'w',
    X̆: 'X',
    x̆: 'x',
    Ŷ: 'Y',
    ŷ: 'y',
    Ÿ: 'Y',
    Y̆: 'Y',
    y̆: 'y',
    Ź: 'Z',
    ź: 'z',
    Ż: 'Z',
    ż: 'z',
    Ž: 'Z',
    ž: 'z',
    ſ: 's',
    ƒ: 'f',
    Ơ: 'O',
    ơ: 'o',
    Ư: 'U',
    ư: 'u',
    Ǎ: 'A',
    ǎ: 'a',
    Ǐ: 'I',
    ǐ: 'i',
    Ǒ: 'O',
    ǒ: 'o',
    Ǔ: 'U',
    ǔ: 'u',
    Ǖ: 'U',
    ǖ: 'u',
    Ǘ: 'U',
    ǘ: 'u',
    Ǚ: 'U',
    ǚ: 'u',
    Ǜ: 'U',
    ǜ: 'u',
    Ứ: 'U',
    ứ: 'u',
    Ṹ: 'U',
    ṹ: 'u',
    Ǻ: 'A',
    ǻ: 'a',
    Ǽ: 'AE',
    ǽ: 'ae',
    Ǿ: 'O',
    ǿ: 'o',
    Þ: 'TH',
    þ: 'th',
    Ṕ: 'P',
    ṕ: 'p',
    Ṥ: 'S',
    ṥ: 's',
    X́: 'X',
    x́: 'x',
    Ѓ: 'Г',
    ѓ: 'г',
    Ќ: 'К',
    ќ: 'к',
    A̋: 'A',
    a̋: 'a',
    E̋: 'E',
    e̋: 'e',
    I̋: 'I',
    i̋: 'i',
    Ǹ: 'N',
    ǹ: 'n',
    Ồ: 'O',
    ồ: 'o',
    Ṑ: 'O',
    ṑ: 'o',
    Ừ: 'U',
    ừ: 'u',
    Ẁ: 'W',
    ẁ: 'w',
    Ỳ: 'Y',
    ỳ: 'y',
    Ȁ: 'A',
    ȁ: 'a',
    Ȅ: 'E',
    ȅ: 'e',
    Ȉ: 'I',
    ȉ: 'i',
    Ȍ: 'O',
    ȍ: 'o',
    Ȑ: 'R',
    ȑ: 'r',
    Ȕ: 'U',
    ȕ: 'u',
    B̌: 'B',
    b̌: 'b',
    Č̣: 'C',
    č̣: 'c',
    Ê̌: 'E',
    ê̌: 'e',
    F̌: 'F',
    f̌: 'f',
    Ǧ: 'G',
    ǧ: 'g',
    Ȟ: 'H',
    ȟ: 'h',
    J̌: 'J',
    ǰ: 'j',
    Ǩ: 'K',
    ǩ: 'k',
    M̌: 'M',
    m̌: 'm',
    P̌: 'P',
    p̌: 'p',
    Q̌: 'Q',
    q̌: 'q',
    Ř̩: 'R',
    ř̩: 'r',
    Ṧ: 'S',
    ṧ: 's',
    V̌: 'V',
    v̌: 'v',
    W̌: 'W',
    w̌: 'w',
    X̌: 'X',
    x̌: 'x',
    Y̌: 'Y',
    y̌: 'y',
    A̧: 'A',
    a̧: 'a',
    B̧: 'B',
    b̧: 'b',
    Ḑ: 'D',
    ḑ: 'd',
    Ȩ: 'E',
    ȩ: 'e',
    Ɛ̧: 'E',
    ɛ̧: 'e',
    Ḩ: 'H',
    ḩ: 'h',
    I̧: 'I',
    i̧: 'i',
    Ɨ̧: 'I',
    ɨ̧: 'i',
    M̧: 'M',
    m̧: 'm',
    O̧: 'O',
    o̧: 'o',
    Q̧: 'Q',
    q̧: 'q',
    U̧: 'U',
    u̧: 'u',
    X̧: 'X',
    x̧: 'x',
    Z̧: 'Z',
    z̧: 'z',
};

export function toBoolean(value) {
    const t = typeof value;
    switch (t) {
    // typeof new String("true") === "object", so handle objects as string via fall-through.
    // See https://github.com/pipwerks/scorm-api-wrapper/issues/3
    case 'object':
    case 'string':
        return (/(true|1)/i).test(value);
    case 'number':
        return !!value;
    case 'boolean':
        return value;
    case 'undefined':
        return null;
    default:
        return false;
    }
}

export function round(number, precision = 2) {
    const coefficient = 10 ** precision;
    return Math.round(number * coefficient) / coefficient;
}

export function isTouchEvent(e) {
    return e.type.toLowerCase().indexOf('touch') > -1;
}

export function getMouseOrTouchEvent(e) {
    return isTouchEvent(e) ? e.originalEvent.touches[0] : e;
}

/**
 * Retrieve query string parameters from an url
 * @export
 * @function getQueryStringParameters
 * @param {String} url - The full url to parse
 * @returns {Object} - An object containing the key/value pairs
 */
 export function getQueryStringParameters(url) {
    if (url.indexOf('?') > -1) {
        const queryString = url.split('?').pop();
        const pairs = queryString.split('&');
        return pairs.reduce((accumulator, pair) => {
            const parsed = decodeURIComponent(encodeURIComponent(pair)).split('=');
            if (parsed.length > 1) {
                const [key, value] = parsed;
                accumulator[key] = value.trim().match(/^({|\[)/) ? safeJsonParse(value) : value;
            }
            return accumulator;
        }, {});
    }
    const zendParsedQueryString = {};
    const zenddModeRegexp = /.*\/mode\/(preview|evaluation)/gi;
    if (zenddModeRegexp.test(url)) {
        zendParsedQueryString.mode = url.replace(zenddModeRegexp, '$1').replace(/\/.*/gi, '');
    }
    const zenddMobileRegexp = /.*\/mobile\/(true|false)/gi;
    if (zenddMobileRegexp.test(url)) {
        zendParsedQueryString.mobile = toBoolean(
            url
                .replace(zenddMobileRegexp, '$1')
                .replace(/\/.*/gi, ''),
        );
    }
    return zendParsedQueryString;
}

export function safeJsonParse(string, reviver) {
    try {
        return JSON.parse(string, reviver);
    } catch (error) {
        return null;
    }
}

export function toObject(queryString) {
    return decodeURIComponent(queryString)
        .replace(/^[^?]*\?/g, '')
        .split('&')
        .reduce((accumulator, pair) => {
            const parsed = decodeURIComponent(encodeURIComponent(pair)).split('=');
            if (parsed.length > 1) {
                const [key, value] = parsed;
                accumulator[key] = value.trim().match(/^({|\[)/) ? safeJsonParse(value) : value;
            }
            return accumulator;
        }, {});
}

export function ajax(url, dataType) {
    return new Promise((resolve, reject) => {
        const xhr = window.XMLHttpRequest
            ? new window.XMLHttpRequest()
            : new window.ActiveXObject('Microsoft.XMLHTTP');

        let type = 'application/x-www-form-urlencoded; charset=UTF-8';
        let completed = false;
        let accept = '*/*';

        switch (dataType) {
        case 'text':
            type = 'text/plain';
            break;
        case 'json':
            type = 'application/json, text/javascript';
            break;
        case 'html':
            type = 'text/html';
            break;
        case 'xml':
            type = 'application/xml, text/xml';
            break;
        default:
            break;
        }

        if (type !== 'application/x-www-form-urlencoded') {
            accept = `${type}, */*; q=0.01`;
        }

        if (xhr) {
            xhr.open('GET', url, true);
            xhr.setRequestHeader('Accept', accept);
            xhr.onreadystatechange = function onreadystatechange() {
                // Ignore repeat invocations
                if (completed) {
                    return;
                }

                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        completed = true;
                        let data;
                        switch (dataType) {
                        case 'json':
                            data = JSON.parse(xhr.responseText);
                            break;
                        case 'xml':
                            data = xhr.responseXML;
                            break;
                        default:
                            data = xhr.responseText;
                            break;
                        }
                        resolve(data);
                    } else {
                        reject(xhr.status);
                    }
                }
            };
            xhr.send();
        }
    });
}

export function toText(html) {
    const div = window.document.createElement('div');
    div.innerHTML = html.replace(/<p>&nbsp;<\/p>/i, '');
    return div.innerText || div.textContent;
}

export function hasOwnProperty(object, property) {
    return Object.prototype.hasOwnProperty.call(object, property);
}

export function isPlainObject(o) {
    return !!o && typeof o === 'object' && Object.prototype.toString.call(o) === '[object Object]';
}

export function isDefined(value) {
    return value !== null && typeof value !== 'undefined';
}

export function getRandomIntInclusive(min, max) {
    const minn = Math.ceil(min);
    const maxx = Math.floor(max);
    return Math.floor(Math.random() * (maxx - minn + 1)) + minn;
}

export function shuffle(array, max, from, to) {
    const shuffled = [];
    const maxLength = this.isDefined(max) ? Math.min(max, array.length) : array.length;
    const startIndex = this.isDefined(from) ? Math.max(0, from - 1) : 0;
    const endIndex = this.isDefined(to) ? Math.min(to - 1, array.length - 1) : array.length - 1;
    const source = array.slice(startIndex, endIndex + 1);
    while (shuffled.length < maxLength && source.length > 0) {
        const index = this.getRandomIntInclusive(0, source.length - 1);
        shuffled.push(source[index]);
        source.splice(index, 1);
    }
    return shuffled;
}

/**
     * @description An helper to test or remove accents from a string
     */
export const Accents = (function createAccents() {
    const chars = Object.keys(CHARACTERS_MAP).join('|');
    const allAccentsRegExp = new RegExp(chars, 'g');
    const firstAccentRegExp = new RegExp(chars, '');

    /**
         * @function remove
         * @description Removes all the accents of a string by replacing each accented
         * character with its equivalent without accent
         * @param {String} string A string that can contain accents
         * @returns {String} The new string without accents
         */
    function remove(string) {
        return string.replace(allAccentsRegExp, (match) => CHARACTERS_MAP[match]);
    }

    /**
         * @function has
         * @description Determine if a string contains accent(s) or not
         * @param {String} string A string that can contain accents
         * @returns {Boolean} A boolean indicating if the @param string contains accents or not
         */
    function has(string) {
        return !!string.match(firstAccentRegExp);
    }

    return {
        remove,
        has,
    };
}());

export function loadScript(src, context) {
    return new Promise((resolve, reject) => {
        const doc = context || window.document;
        const js = doc.createElement('script');
        js.onload = resolve;
        js.onerror = reject;
        js.async = true;
        js.src = src;
        doc.getElementsByTagName('head')[0].appendChild(js);
    });
}

export function isLocalContext() {
    return window.location.hostname === 'localhost';
}

export function uuidv4(a) {
    // eslint-disable-next-line
    return a
        ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
        : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuidv4);
}

export function mix(baseClass, ...mixins) {
    mixins.forEach((mixin) => {
        Object.getOwnPropertyNames(mixin)
            .concat(Object.getOwnPropertySymbols(mixin))
            .forEach((key) => {
                if (key in baseClass.prototype) {
                    return;
                }

                const sourceDescriptor = Object.getOwnPropertyDescriptor(mixin, key);
                sourceDescriptor.enumerable = false;

                Object.defineProperty(baseClass.prototype, key, sourceDescriptor);
            });
    });
}

export function nameInputFormatter(value) {
    return value
        .trim()
        .substring(0, Constants.CONSTRAINTS.name.max);
}

export function trigger(htmlElement, type, options) {
    const event = new CustomEvent(type, {
        bubbles: false,
        cancelable: false,
        composed: false,
        details: null,
        ...options,
    });
    htmlElement.dispatchEvent(event);
    return event;
}

export function scrollToBottom(htmlElement = document.body) {
    console.log('🚀 :: scrollToBottom :: htmlElement', htmlElement);
    const scrollTopMax = htmlElement.scrollTopMax
        || htmlElement.scrollHeight;
    console.log('🚀 :: scrollToBottom :: scrollTopMax', scrollTopMax);
    if (scrollTopMax > 0) {
        smoothScroll(htmlElement).to(scrollTopMax);
    }
}


const easeInOutQuart = (time, from, distance, duration) => {
    let ttime = time / (duration / 2);
    if (ttime < 1) {
        return (distance / 2) * ttime * ttime * ttime * ttime + from;
    }
    ttime -= 2;
    return (-distance / 2) * (ttime * ttime * ttime * ttime - 2) + from;
};

/**
 * @function smoothScroll
 * @description Smooth scroll an element to a defined scrollTop
 * @export
 * @param {HTMLElement} element The element to scroll
 * @returns {Object} An object with "to" function available to start the smooth scroll
 * @example smoothScroll(element).to(scrollTopTo, duration)
 */
export function smoothScroll(element) {
    const { scrollTop } = element;
    const target = element;
    return {
        to: (scrollTopTo, duration) => {
            const distance = scrollTopTo - scrollTop;
            const startTime = Date.now();
            const dduration = Number.isNaN(Number(duration)) ? 400 : duration;
            const timer = window.setInterval(() => {
                const time = Date.now() - startTime;
                const scrollTo = easeInOutQuart(time, scrollTop, distance, dduration);
                if (time >= dduration) {
                    window.clearInterval(timer);
                }
                target.scrollTop = scrollTo;
            }, 1000 / 60);
        },
    };
}