import React from 'react';
import classNames from 'classnames';
import Gold from '../../../../assets/img/gold.png';
import Silver from '../../../../assets/img/silver.png';
import Bronze from '../../../../assets/img/bronze.png';
import Img from '../../../common/img/Img';
import { BackgroundContext } from '../../../common/background-context/BackgroundContext';
import styles from './End.module.css';

export default function End(props) {
    console.log('🚀 :: End :: props', props);
    const { changeBackground } = React.useContext(BackgroundContext);
    React.useEffect(() => {
        changeBackground('podium');
    }, []);
    return (
        <div className={classNames(styles.container, 'bounce-in')}>
            <div className={classNames(styles.name, 'bango')}>{props.name}</div>
            <div className={classNames(styles.rank, 'broken-console')}>{props.rank}<span className={styles.rankText}>{props.rank < 2 ? 'ère' : 'e'}</span> place</div>
            {props.rank <= 3 && <div className={styles.medal}><Img src={getMedalImg(props.rank)}/></div>}
            <div className={classNames(styles.score, 'bango')}>{props.score}<span className={styles.pointsText}> point{props.score > 1 ? 's' : ''}</span></div>
        </div>
    );
}

function getMedalImg(rank) {
    switch (rank) {
    case 1: return Gold;
    case 2: return Silver;
    case 3: return Bronze;
    default: return null;
    }
}
