import React from 'react';
import TeamNameForm from './TeamNameForm';
import TeamPlayersForm from './TeamPlayersForm';

export default class TeamForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            teamName: null,
        };
        this.handleSetTeamName = this.handleSetTeamName.bind(this);
    }

    handleSetTeamName(teamName) {
        console.log('🚀 :: TeamForm :: handleSetTeamName :: teamName', teamName);
        this.setState({
            teamName,
        });
    }

    render() {
        return this.state.teamName
            ? <TeamPlayersForm teamName={this.state.teamName} {...this.props} />
            : <TeamNameForm setTeamName={this.handleSetTeamName} {...this.props} />;
    }
}
