import classNames from 'classnames';
import React from 'react';
import styles from '../Game.module.css';

export default function Header(props) {
    if (props.gameEnded || !props.data) {
        return null;
    }
    return (
        <header className={styles.header}>
            <div className={classNames(styles.questionProgression, 'circle', 'bango')}>
                <div className={styles.questionProgressionValue}>{props.data.questionNumber}/{props.data.questionTotal}</div>
            </div>
        </header>
    );
}
