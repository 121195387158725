import React from "react";
import OnlineWhite from "../../../assets/img/logo-white.svg";
import Img from "../img/Img";
import styles from "./Logo.module.css";

export default function Logo(props) {
    return (
        <div>
            <Img
                className={styles.logoImg}
                alt="Logo"
                src={OnlineWhite}
                draggable="false"
                onDragStart={() => false}
            ></Img>
        </div>
    );
}
