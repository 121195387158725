import classNames from 'classnames';
import React from 'react';
import styles from '../Game.module.css';

export default function Footer(props) {
    return (
        <footer className={classNames(styles.footer, 'bango')}>
            <div className={styles.content}>
                <div>{props.name}</div>
                <div className={styles.scoreContainer}><span className={styles.score}>{props.score}</span> point{props.score > 1 ? 's' : ''}</div>
            </div>
        </footer>
    );
}
