import classNames from 'classnames';
import React from 'react';
import Constants from '../../../../common/js/constants';
import Button from '../../../common/button/Button';
import styles from './Choice.module.css';

export default class Choice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.ref = React.createRef();
    }

    handleSubmit(e) {
        console.log('🚀 :: Choice :: handleSubmit :: e', e);
        e.preventDefault();
        const selected = this.ref.current.querySelectorAll(`.${styles.selected}`);
        const responses = Array.from(selected).map((dom) => dom.dataset.id);
        this.props.onResponse(this.props.data.id, responses);
    }

    render() {
        const { data } = this.props;
        const { multiple } = data;
        const proposals = data.proposals.map((proposal, i) => {
            const ref = React.createRef();
            const handleClick = (currentRef) => {
                if (multiple) {
                    currentRef.current.classList.toggle(styles.selected);
                    const disabled = Array.from(
                        this.ref.current.querySelectorAll(`.${styles.selected}`),
                    ).length === 0;
                    this.setState({
                        disabled,
                    });
                } else {
                    this.props.onResponse(data.id, [proposal.id]);
                }
            };
            return (
                <div
                    key={proposal.id}
                    data-id={proposal.id}
                    ref={ref}
                    className={classNames(styles.buttons, getClassName(i))}
                    onClick={handleClick.bind(this, ref)}
                ></div>
            );
        });

        return (
            <form className={styles.wrapper} onSubmit={this.handleSubmit}>
                <div
                    ref={this.ref}
                    className={multiple ? styles.multiple : styles.single}
                >{proposals}</div>
                {multiple
                    && <div className={styles.validation}>
                        <Button className={classNames(this.state.disabled && styles.disabled)}>Valider</Button>
                    </div>}
            </form>
        );
    }
}

function getClassName(index) {
    return `${Constants.COLORS[index]}`;
}
