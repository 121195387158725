// import sha256 from 'sha256';
import { fetchMethod, isLocalContext } from './utils';
import config from './config';

/**
 * A json content type request header
 */
const jsonContentTypeHeaders = {
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
};

const URL = isLocalContext() ? `https://live.olfp.net${config.path}/` : `${config.path}/`;


export async function create() {
    const requestParams = {
        ...jsonContentTypeHeaders,
        method: 'get',
        body: null,
    };

    const result = await fetchMethod(`${URL}api/games/create`, requestParams)
        .then(parseAPIResponse);
    return result;
}

export async function exists(pin) {
    const requestParams = {
        ...jsonContentTypeHeaders,
        method: 'get',
        body: null,
    };

    const result = await fetchMethod(`${URL}api/games/${pin}`, requestParams)
        .then(parseAPIResponse);
    return result;
}

export async function nameExists(pin, name) {
    const requestParams = {
        ...jsonContentTypeHeaders,
        method: 'get',
        body: null,
    };

    const result = await fetchMethod(`${URL}api/games/${pin}/name/${name}`, requestParams)
        .then(parseAPIResponse);
    return result;
}


function parseAPIResponse(response) {
    if (response && response.ok) {
        return response.json();
    }
    return Promise.reject(response.status);
}


// function checksum(url, uid, data) {
//     const timestamp = new Date().getTime();
//     const hash = sha256.create();
//     hash.update(url + (uid || '') + safeJSONStringify(data) + timestamp);
//     return `${url + (/\?.+=.*/g.test(url) ? '&' : '?')}t=${timestamp}&c=${hash.hex()}`;
// }


function safeJSONStringify(obj) {
    return isPlainObject(obj)
        ? JSON.stringify(obj)
        : '';
}

function isPlainObject(o) {
    return !!o && typeof o === 'object' && Object.prototype.toString.call(o) === '[object Object]';
}
