
import classNames from "classnames";
import React from "react";
import styles from "./Input.module.css";

const Input = React.forwardRef((props, ref) => {
    const { className, ...domNativeAttributes } = props;
    return (
        <input
            ref={ref}
            className={classNames(styles.input, className)}
            spellCheck="false"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            {...domNativeAttributes}
        />
    );
});
export default Input;