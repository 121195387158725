const Constants = {
    CLICK_TOUCH: 'ontouchstart' in window ? 'touchend' : 'click',
    DOWN_TOUCHSTART: 'ontouchstart' in window ? 'touchstart' : 'mousedown',
    MOVE_TOUCHMOVE: 'ontouchstart' in window ? 'touchmove' : 'mousemove',
    UP_TOUCHEND: 'ontouchstart' in window ? 'touchend' : 'mouseup',

    INPUT: 'input',
    BLUR: 'blur',
    KEY_DOWN: 'keydown',
    KEY_UP: 'keyup',

    LOAD: 'load',
    LOADED_META_DATA: 'loadedmetadata',

    CHANGE: 'change',
    WHEEL: 'wheel',
    RESIZE: 'orientationchange resize',
    UNLOAD: 'beforeunload unload',
    TRANSITION_START: 'transitionstart webkitTransitionStart otransitionstart oTransitionStart msTransitionStart',
    TRANSITION_END: 'transitionend webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd',

    CONSTRAINTS: {
        pin: {
            length: 7,
        },
        name: {
            min: 3,
            max: 10,
        },
    },

    COLORS: 'blue pink green violet'.split(' '),

    /**
     * Socket IO reserved events
     */
    CONNECT: 'connect',
    CONNECT_ERROR: 'connect_error',
    DISCONNECT: 'disconnect',

    /**
     * Custom socket events
     */
    PLAYER_JOINED: 'player-joined',
    PLAYER_DISCONNECT: 'player-disconnect',
    PRESENTER_DISCONNECT: 'presenter-disconnect',
    COUNTDOWN: 'countdown',
    SET_QUESTION: 'set-question',
    RESPONSE_QUESTION: 'response-question',
    SET_QUESTION_RESULT: 'set-question-result',
    QUESTION_TIMEOUT: 'question-timeout',
    END_GAME: 'end-game',
    GAME_DESTROYED: 'game-destroyed',
    GAME_ENDED: 'game-ended',
    GAME_LOCKED: 'game-locked',
    REMOVE_DISCONNECT_LISTENER: 'remove-disconnect-listener',
    FORCE_DISCONNECTED: 'force-disconnected',
    GAME_UNAVAILABLE: 'game-unavailable',
    QUESTION_RECEIVED: 'question-received',
};
export default Constants;
