import React from 'react';
import Header from './header/Header';
import Main from './main/Main';
import Footer from './footer/Footer';
import styles from './Game.module.css';

export default class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            foo: 'bar',
        };
    }

    render() {
        return (
            <div className={styles.container}>
                {!this.props.timeout && <Header { ...this.props } />}
                <Main { ...this.props } />
                {!this.props.gameEnded && <Footer { ...this.props } />}
            </div>
        );
    }
}
