import React from 'react';
import io from 'socket.io-client';
import config from './common/js/config';
import Constants from './common/js/constants';
import PinForm from './components/pin/PinForm';
import NameForm from './components/name/NameForm';
import TeamForm from './components/team/TeamForm';
import Game from './components/game/Game';
import Toaster from './common/js/toaster';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-toastify/dist/ReactToastify.css';
import styles from './App.module.css';
import { exists } from './common/js/web-service';
import classNames from 'classnames';
import { BackgroundContext, backgrounds } from './components/common/background-context/BackgroundContext';
import Logo from './components/common/logo/Logo';
import Livequiz from './components/common/Livequiz/Livequiz';
import { uuidv4 } from './common/js/utils';

/**
 * @constant
 * @description Default state of the App
 * @type {object}
 */
const DEFAULT_STATE = {
    screen: 'pin',
    pin: '',
    name: '',
    data: null,
    timeout: false,
    responded: false,
    points: 0,
    score: 0,
    countdown: -1,
    teamCountdown: false,
    isCorrect: false,
    gameEnded: false,
    correctResponses: 0,
    correctResponsesChain: 0,
    oldCorrectResponsesChain: 0,
    countdownKey: 0,
    oldSid: null,
};

/**
 * @class App
 * @extends {React.Component}
 */
class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_STATE,
        };

        this.uuid = uuidv4();
        this.socket = null;
        this.gameData = null;
        this.team = null;

        this.handleSetScreen = this.handleSetScreen.bind(this);
        this.handleSetPin = this.handleSetPin.bind(this);
        this.handleSetName = this.handleSetName.bind(this);
        this.handleSetTeam = this.handleSetTeam.bind(this);
        this.handleSetResponse = this.handleSetResponse.bind(this);
        this.handleSetGameData = this.handleSetGameData.bind(this);

        this.ref = React.createRef();
    }

    async componentDidMount() {
        //
    }

    componentWillUnmount() {
        console.log('🚀 :: App :: componentWillUnmount :: componentWillUnmount');
        // remove listeners and cancel all async tasks
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.name !== this.state.name
            && this.state.pin.length
            && this.state.name.length
            && !this.socket) {
            // connects the socket when pin and name are defined
            this.connect();
            return;
        }
    }

    render() {
        return (
            <div
                ref={this.ref}
                className={classNames(styles.wrapper)}
                style={{ backgroundImage: `url(${backgrounds[this.context.background]})`}}
            >
                {this.route()}
            </div>
        );
    }

    handleSetScreen(screen) {
        this.setState({
            screen,
        });
    }

    handleSetName(name) {
        this.setState({
            name,
        });
    }

    handleSetTeam({ name, players }) {
        console.log('🚀 :: App :: handleSetTeam :: { name, players }', { name, players });
        this.team = { name, players };

        this.setState({
            name,
        });
    }

    handleSetPin(pin) {
        this.setState({
            pin,
        });
    }

    handleSetGameData(data) {
        console.log('🚀 :: App :: handleSetGameData :: data', data);
        this.gameData = data;
    }

    handleSetResponse(questionId, responses) {
        console.log('🚀 :: App :: handleSetResponse :: questionId, responses', questionId, responses);
        if (this.socket) {
            this.socket.emit(Constants.RESPONSE_QUESTION, {
                question: { id: questionId },
                responses,
            });
        }
        this.setState({
            responded: true,
        });
        return this;
    }

    async connect() {
        console.log('🚀 ----------------------------------->>>> :: App :: connect :: connect called');
        const { uuid } = this;
        const { pin, name, oldSid } = this.state;
        const { error, result } = await exists(pin);
        console.log('🚀 :: PinForm :: handleSubmit :: error, result', error, result);
        if (error) {
            Toaster.dos();
            this.reset();
            return this;
        }
        if (!result || result.ended) {
            this.reset();
            Toaster.gameUnavailable();
            return this;
        }

        console.log('🚀 :: App :: connect :: oldSid', oldSid);
        const path = `${config.path}/${pin}`;
        const query = { uuid, name };
        if (this.gameData.teamMode) {
            const { name: teamName, players } = this.team;
            query.name = teamName;
            query.data = JSON.stringify({
                players,
            });
        }

        this.socket = io.connect(`${config.host}${path}`, {
            path,
            query,
        });

        // écouteur de connexion réussie et donc d'objet Peer créé coté serveur
        this.socket.on(Constants.CONNECT, async () => {
            console.log('this.socket', this.socket);
            console.log('🚀 :: App :: this.socket.on :: this.socket.id', this.socket.id);
        });

        // écouteur d'erreur lors de la connexion
        this.socket.on(Constants.CONNECT_ERROR, async (err) => {
            console.log('🚀 :: App :: this.socket.on :: err', err);

            if (err.message === 'Invalid namespace') {
                this.reset();
            }
        });

        // écouteur de déconnexion
        this.socket.on(Constants.DISCONNECT, (reason) => {
            console.log('🚀 :: App :: this.socket.on :: reason', reason);
        });

        // remove the disconnect listener for silent disconnection
        // this is used when a game is destroyed server-side but players are still connected
        this.socket.on(Constants.REMOVE_DISCONNECT_LISTENER, async () => {
            this.socket.off(Constants.DISCONNECT);
        });

        this.socket.on(Constants.PRESENTER_DISCONNECT, async () => {
            Toaster.presenterDisconnected();
        });

        this.socket.on(Constants.COUNTDOWN, (data) => {
            console.log('🚀 :: COUNTDOWN :: data', data);

            const { countdown, team } = data;
            this.setState({
                countdown,
                teamCountdown: team,
                countdownKey: this.state.countdownKey + 1,
                responded: false,
                timeout: false,
                data: null,
            });
        });

        this.socket.on(Constants.SET_QUESTION, async (data) => {
            console.log('🚀 :: SET_QUESTION :: data', data);

            this.socket.emit(Constants.QUESTION_RECEIVED);

            this.setState({
                countdown: -1,
                responded: false,
                timeout: false,
                data,
            });

            this.gameData.started = true;
        });

        this.socket.on(Constants.SET_QUESTION_RESULT, (data) => {
            console.log('🚀 :: App :: this.socket.on :: data', data);

            const {
                points, score, isCorrect, rank, correctResponsesChain, oldCorrectResponsesChain,
            } = data;
            const newState = {
                points,
                score,
                isCorrect,
                rank,
                correctResponsesChain,
                oldCorrectResponsesChain,
            };
            if (isCorrect) {
                newState.correctResponses = this.state.correctResponses + 1;
            }
            this.setState(newState);
        });

        this.socket.on(Constants.QUESTION_TIMEOUT, async (data) => {
            console.log('🚀 :: socket.on :: Constants.QUESTION_TIMEOUT', Constants.QUESTION_TIMEOUT);

            this.setState({
                countdown: -1,
                timeout: true,
                points: 0,
                isCorrect: false,
                rank: data.rank,
            });
        });

        this.socket.on(Constants.GAME_LOCKED, async () => {
            console.log('🚀 :: socket.on :: Constants.GAME_LOCKED');

            this.reset();
            Toaster.gameLocked();
        });

        this.socket.on(Constants.GAME_ENDED, async (data) => {
            console.log('🚀 :: socket.on :: Constants.GAME_ENDED', data);

            const { rank } = data;
            this.setState({
                countdown: -1,
                gameEnded: true,
                rank,
            });
        });

        this.socket.on(Constants.FORCE_DISCONNECTED, async () => {
            console.log('🚀 :: App :: this.socket.on :: Constants.FORCE_DISCONNECTED');
            this.reset();
            Toaster.banned();
        });

        this.socket.on(Constants.GAME_UNAVAILABLE, async () => {
            console.log('🚀 :: App :: this.socket.on :: Constants.GAME_UNAVAILABLE');
            this.reset();
            Toaster.gameUnavailble();
        });

        return this;
    }

    disconnect() {
        if (this.socket) {
            this.socket.removeAllListeners();
            this.socket.disconnect(true);
        }
        this.socket = null;
        return this;
    }

    async reset() {
        this.disconnect();
        this.gameData = null;
        this.context.changeBackground('intro');
        this.setState(DEFAULT_STATE);
        return this;
    }

    route() {
        switch (this.state.screen) {
        case 'pin': return (
            <>
                <Logo />
                <Livequiz />
                <PinForm
                    onChange={this.handleSetPin}
                    setGameData={this.handleSetGameData}
                    setScreen={this.handleSetScreen}
                />
            </>
        );

        case 'name': return (
            <>
                <Logo />
                <Livequiz />
                { this.gameData.teamMode
                    ? <TeamForm
                        pin={this.state.pin}
                        onChange={this.handleSetTeam}
                        setScreen={this.handleSetScreen}
                        parentRef={this.ref}
                    />
                    : <NameForm
                        pin={this.state.pin}
                        onChange={this.handleSetName}
                        setScreen={this.handleSetScreen}
                    />
                }
            </>
        );

        case 'game': return <Game
            {...this.state}
            gameData={this.gameData}
            team={this.team}
            onResponse={this.handleSetResponse}
            setStage={this.setStage}
        />;
        default: return null;
        }
    }
}

App.contextType = BackgroundContext;

export default App;
