
import classNames from "classnames";
import React from "react";
import styles from "./Button.module.css";

const Button = React.forwardRef((props, ref) => {
    const { children, className, ...domNativeAttributes } = props;
    return (
        <button ref={ref} className={classNames(className, styles.button, 'bango')} {...domNativeAttributes}>
            <span className={styles.text}>{children}</span>
            <div className={classNames(styles.circle, 'circle')}>
                <i className="icon-arrow-right1"></i>
            </div>
        </button>
    );
});

export default Button;
