import React from 'react';
import Constants from '../../common/js/constants';
import { exists } from '../../common/js/web-service';
import Toaster from '../../common/js/toaster';
import classNames from 'classnames';
import styles from './PinForm.module.css';
import Logo from '../common/logo/Logo';
import Livequiz from '../common/Livequiz/Livequiz';
import Button from '../common/button/Button';
import Input from '../common/input/Input';

export default class PinForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            status: '',
            redirect: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({
            value: pinInputFormatter(e.target.value),
            status: '',
        });
    }

    handleSubmit(e) {
        console.log('🚀 :: PinForm :: handleSubmit :: e', e);
        if (e) {
            e.preventDefault();
        }
        this.usePin(this.state.value);
    }

    async usePin(pin) {
        const notFoundError = () => {
            this.setState({
                status: styles.invalid,
            });
            Toaster.pinNotFound();
        };
        if (pin.length < Constants.CONSTRAINTS.pin.length) {
            return notFoundError();
        }

        const { error, result } = await exists(pin);
        console.log('🚀 :: PinForm :: handleSubmit :: error, result', error, result);
        if (error) {
            Toaster.dos();
        } else if (result && !result.ended) {
            const { locked, started, teamMode } = result;
            if (locked) {
                Toaster.gameLocked();
                this.setState({
                    value: '',
                });
            } else {
                this.props.onChange(pin);
                this.props.setGameData({ started, teamMode });
                this.setState({
                    redirect: true,
                });
            }
        } else {
            notFoundError();
        }
        return true;
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('pin')) {
            const pin = pinInputFormatter(searchParams.get('pin'));
            this.usePin(pin);
        }
    }

    componentDidUpdate() {
        if (this.state.redirect) {
            this.props.setScreen('name');
        }
    }

    render() {
        const { value, redirect } = this.state;
        if (redirect) {
            return null;
        }
        return (
            <>   
                <form onSubmit={this.handleSubmit}>
                    <div>
                        Entrez le <span className="yellow"><strong>code PIN</strong></span> du jeu
                    </div>
                    <div className={styles.pinContainer}>
                        <Input
                            className={classNames(styles.input, this.state.status)}
                            value={value}
                            onChange={this.handleChange}
                            size={Constants.CONSTRAINTS.pin.length}
                            placeholder="PIN"
                        />
                    </div>
                    <div>
                        <Button>Valider</Button>
                    </div>
                </form>
            </>
        );
    }
}

function pinInputFormatter(value) {
    return value
        .trim()
        .replace(/[^0-9]/ig, '')
        .substring(0, Constants.CONSTRAINTS.pin.length);
}
