import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './common/css/variables.css';
import './common/css/helpers.css';
import './assets/fonts/bango-pro/stylesheet.css';
import './assets/fonts/broken-console/stylesheet.css';
import './assets/fonts/helvetica/stylesheet.css';
import './assets/fonts/livequiz-player/css/livequiz-player-font.css';
import './index.css';

import { withErrorBoundary } from 'react-error-boundary'
import { BackgroundProvider, backgrounds } from './components/common/background-context/BackgroundContext';
import CorrectImg from './assets/img/correct.svg';
import IncorrectImg from './assets/img/incorrect.svg';

function ErrorFallback({ error }) {
    return (
        <div>
            <h2>Something went wrong.</h2>
            <details style={{ whiteSpace: "pre-wrap" }}>
                {error && error.toString()}
            </details>
        </div>
    )
}

const AppWithErrorBoundary = withErrorBoundary(App, {
    FallbackComponent: ErrorFallback,
    onError(error, info) {
        // Do something with the error
        // E.g. log to an error logging client here
    },
});

(async () => {
    // preload backgrounds
    await preload();
    ReactDOM.render(
        <React.StrictMode>
            <BackgroundProvider>
                <AppWithErrorBoundary></AppWithErrorBoundary>
            </BackgroundProvider>
        </React.StrictMode>,
        document.getElementById('root'),
    );
})();


export function preload() {
    const promises = Object.values(backgrounds)
        .concat([CorrectImg, IncorrectImg])
        .map((bg) => new Promise((resolve, reject) => {
            const img = new Image();
            img.src = bg;
            if (img.complete) {
                resolve();
            } else {
                img.onload = resolve;
                img.onerror = reject;
                img.onabort = reject;
            }
        }));
    return Promise.all(promises);
}