import React from 'react';
import { BackgroundContext } from '../../common/background-context/BackgroundContext';
import Choice from '../main/choice/Choice';
import FillIn from '../main/fill-in/FillIn';
import Order from '../main/order/Order';

export default function Question(props) {
    const { changeBackground } = React.useContext(BackgroundContext);
    React.useEffect(() => {
        changeBackground('question');
    }, []);

    const { type } = props.data;
    switch (type) {
    case 'choice': return <Choice data={props.data} onResponse={props.onResponse}/>;
    case 'order': return <Order data={props.data} onResponse={props.onResponse}/>;
    case 'fill-in': return <FillIn data={props.data} onResponse={props.onResponse}/>;
    default: return null;
    }
}
