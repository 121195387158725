import classNames from 'classnames';
import React from 'react';
import Constants from '../../common/js/constants';
import Toaster from '../../common/js/toaster';
import { nameInputFormatter, scrollToBottom } from '../../common/js/utils';
import Button from '../common/button/Button';
import Input from '../common/input/Input';
import Livequiz from '../common/Livequiz/Livequiz';
import Logo from '../common/logo/Logo';
import styles from './TeamNameForm.module.css';

export default class TeamPlayersForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            list: [
                getItem(),
                getItem(),
            ],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleAddPlayer = this.handleAddPlayer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.containerRef = React.createRef();
    }

    handleChange(ref) {
        const index = ref.current.dataset.key;
        const { list } = this.state;
        const listEl = list[index];
        if (listEl) {
            listEl.name = nameInputFormatter(ref.current.value);
            this.setState({
                list,
            });
        }
    }

    handleAddPlayer(e) {
        e.preventDefault();
        const { list } = this.state;
        this.setState({
            list: [
                ...list,
                getItem(),
            ],
        });
        scrollToBottom(this.props.parentRef.current);
    }

    async handleSubmit(e) {
        e.preventDefault();

        const items = [...this.containerRef.current.querySelectorAll(`.${styles.item}`)];
        items.forEach((domEl) => {
            domEl.classList.remove(styles.invalid);
        });
        const invalid = items.find((el) => el.value.length
            && el.value.length < Constants.CONSTRAINTS.name.min);

        const atLeastOneValid = items.some((el) => (
            el.value.length >= Constants.CONSTRAINTS.name.min
        ));

        if (invalid) {
            invalid.classList.add(styles.invalid);
            Toaster.pseudoConstraints();
        } else if (atLeastOneValid) {
            const players = this.state.list.filter(({ name }) => name.length);
            this.props.onChange({
                name: this.props.teamName,
                players,
            });
            this.setState({
                redirect: true,
            });
        }
    }

    componentDidUpdate() {
        if (this.state.redirect) {
            this.props.setScreen('game');
        }
    }

    render() {
        const { redirect } = this.state;
        if (redirect) {
            return null;
        }
        return (
            <>
                <form onSubmit={this.handleSubmit} style={{maxHeight: '38vh'}}>
                    <div>
                        Entrez les <span className="yellow"><strong>Pseudos</strong></span> des joueurs
                    </div>
                    <div ref={this.containerRef} className={styles.teamContainer}>
                        { this.state.list.map((el, i) => {
                                const ref = React.createRef();
                                return <div key={i}>
                                    <Input
                                        data-key={i}
                                        ref={ref}
                                        value={el.name}
                                        className={classNames(styles.item, 'bounce-in')}
                                        onChange={this.handleChange.bind(this, ref)}
                                        size={Constants.CONSTRAINTS.name.max}
                                        placeholder="Pseudo" 
                                    />
                                </div>;
                        }) }
                    </div>
                    <div className={styles.flex}>
                        <button className={classNames(styles.circle, 'circle')} onClick={this.handleAddPlayer}>
                            <i className="icon-plus-solid"></i>
                        </button>
                    </div>
                    <div>
                        <Button className={styles.marginBottom}>Valider</Button>
                    </div>
                </form>
            </>
        );
    }
}

function getItem() {
    return { name: '' };
}
