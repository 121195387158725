import classNames from 'classnames';
import React from 'react';
import { getRandomIntInclusive } from '../../../../common/js/utils';
import Input from '../../../common/input/Input';
import Button from '../../../common/button/Button';
import styles from './FillIn.module.css';

export default class FillIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            value: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.colorClassName = getClassName();
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.ref.current.focus();
    }

    handleChange(e) {
        this.setState({
            value: e.target.value,
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.onResponse(this.props.data.id, this.state.value);
    }

    render() {
        return (
            <form className={styles.wrapper} onSubmit={this.handleSubmit}>
                <div className={styles.center}>
                    <div className={styles.inputWrapper}>
                        <i className="icon-pen"></i>    
                        <Input
                            ref={this.ref}
                            className={classNames(styles.input)}
                            value={this.state.value}
                            onChange={this.handleChange}
                            placeholder="Saisis ta réponse"
                            maxLength="30"
                        />
                    </div>
                </div>
                <div className={styles.validation}>
                    <Button>Valider</Button>
                </div>
            </form>
        );
    }
}

const Colors = [
    styles.red,
    styles.blue,
    styles.orange,
    styles.green,
];

function getClassName() {
    return Colors[getRandomIntInclusive(0, Colors.length - 1)];
}
