import React from 'react';
import intro from '../../../assets/img/intro.jpg';
import question from '../../../assets/img/question.jpg';
import podium from '../../../assets/img/podium.jpg';
  
export const backgrounds = {
    intro,
    question,
    podium,
};

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
export const BackgroundContext = React.createContext({
    background: backgrounds.intro,
    changeBackground: () => {},
});

export const BackgroundProvider = ({ children }) => {
    const [background, setBackground] = React.useState('intro');
    const changeBackground = (bg) => {
        setBackground(bg);
    };
 
    return (
        <BackgroundContext.Provider value={{ background, changeBackground }}>
            {children}
        </BackgroundContext.Provider>
    )
}
