import React from "react";
import LivequizPNG from "../../../assets/img/livequiz.png";
import Img from "../img/Img";
import styles from "./Livequiz.module.css";

export default function Livequiz(props) {
    return (
        <div>
            <Img
                className={styles.livequizImg}
                alt="LiveQuiz"
                src={LivequizPNG}
                draggable="false"
                onDragStart={() => false}
            ></Img>
        </div>
    );
}
