import React from 'react';
import Loader from 'react-loader-spinner';
import End from './end/End';
import Welcome from './welcome/Welcome';

import styles from '../Game.module.css';
import Question from '../question/Question';
import CountDown from './countdown/CountDown';
import classNames from 'classnames';
import Feedback from './feedback/Feedback';

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            foo: 'bar',
        };
    }

    route() {
        // game ended (manually or normal)
        if (this.props.gameEnded) {
            return <End {...this.props} />;
        }
        // question timeout (responded or not)
        if (this.props.timeout) {
            return <Feedback {...this.props} />;
        }
        // the player has responded to the question but not
        // all others and question timeout is not complete
        if (this.props.responded) {
            return (
                <div>
                    <Loader
                        type="Oval"
                        color="#fff"
                        height={'12vmin'}
                        width={'12vmin'}
                    />
                    <div>En attente des autres joueurs</div>
                </div>
            );
        }
        // display the question
        if (this.props.data) {
            return <Question {...this.props}  />;
        }
        // countdown before the question display (+ team chat in team mode)
        if (this.props.countdown > -1) {
            return (
                <CountDown
                    key={this.props.countdownKey}
                    duration={this.props.countdown}
                    team={this.props.teamCountdown}
                />
            );
        }
        // the player just get connected
        return <Welcome {...this.props} />;
    }

    render() {
        console.log('🚀 :: Main :: render :: render', this.props);

        const isQuestionStep = !this.props.gameEnded
            && !this.props.timeout
            && !this.props.responded
            && !!this.props.data;

        const isWelcomeStep = !this.props.gameEnded
            && !this.props.timeout
            && !this.props.responded
            && !this.props.data
            && this.props.countdown === -1;

        return (
            <main className={classNames(
                styles.main,
                (isWelcomeStep || this.props.countdown > -1) ? styles.countdown : '',
                this.props.gameEnded ? styles.linearPadding : '',
            )}>
                {this.route()}
            </main>
        );
    }
}
